<template>
   <div class="trainingPlan">
     <div class="topl">
       <div class="topl-box">
         <span>评估结论：</span>
         <div style="color: #fc0505">{{title}}</div>
       </div>
       <div class="topl-box">
         <span>附加矫正：</span>
         <div>无</div>
       </div>
     </div>

     <div>
       <ul class="content bakc">
         <li class="one">
           <p>训练阶段</p>
           <i class="el-icon-circle-plus-outline" @click="add"></i>
         </li>
         <li class="tow">训练工具</li>
         <li>眼别</li>
         <li>每次时间</li>
         <li>次数</li>
         <li>周期</li>
         <li>训练方式</li>
         <li>操作</li>
       </ul>
       <div class="content-box">
         <ul class="content" v-for="(item, idx) in arr" :key="idx">
           <li class="one"><input v-model="item.S_OP_Json.S_OP_IT_Stage" :disabled="item.type" type="text"></li>
           <li class="tow"><input  v-model="item.S_OP_Json.S_OP_IT_Tool" :disabled="item.type" type="text"></li>
           <li>
             <input v-if="!item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Eyes" type="text">
             <el-select v-if="item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Eyes" placeholder="请选择">
               <el-option
                   v-for="item in yb"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
             </el-select>
           </li>
           <li>
             <input v-if="!item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Duration" type="text">
             <el-select v-if="item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Duration" placeholder="请选择">
               <el-option
                   v-for="item in time"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
             </el-select>
           </li>
           <li>
             <input v-if="!item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Frequency" type="text">
             <el-select v-if="item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Frequency" placeholder="请选择">
               <el-option
                   v-for="item in num"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
             </el-select>
           </li>
           <li>
             <input v-if="!item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Period" type="text">
             <el-select v-if="item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Period" placeholder="请选择">
               <el-option
                   v-for="item in zq"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
             </el-select>
           </li>
           <li>
             <input v-if="!item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Method" type="text">
             <el-select v-if="item.S_OP_Json.type" v-model="item.S_OP_Json.S_OP_IT_Method" placeholder="请选择">
               <el-option
                   v-for="item in fs"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
             </el-select>
           </li>
           <li><el-button type="danger" size="small" @click="dele(idx)" plain>删除</el-button></li>
         </ul>
       </div>
     </div>

     <div>
       <el-button @click="$router.back(-1)">返回</el-button>
       <el-button type="primary" @click="saves">保存</el-button>
     </div>
   </div>
</template>

<script>
import pf from '@/publicFn/baseFn'
import axios from "@/api/axios"
export default {
  name: "trainingPlan",
  data() {
    return {
      arr: [],
      title: '',
      list: [

      ],
      yb: [
        {
          value: '右',
          label: '右'
        },
        {
          value: '左',
          label: '左'
        },
        {
          value: '左、右',
          label: '左、右'
        },
        {
          value: '双眼',
          label: '双眼'
        },
      ],
      time: [
        {
          value: '1分钟',
          label: '1分钟'
        },
        {
          value: '2分钟',
          label: '2分钟'
        },
        {
          value: '3分钟',
          label: '3分钟'
        },
        {
          value: '4分钟',
          label: '4分钟'
        },
        {
          value: '5分钟',
          label: '5分钟'
        },
        {
          value: '6分钟',
          label: '6分钟'
        },
        {
          value: '7分钟',
          label: '7分钟'
        },
        {
          value: '8分钟',
          label: '8分钟'
        },
        {
          value: '9分钟',
          label: '9分钟'
        },
        {
          value: '10分钟',
          label: '10分钟'
        },
        {
          value: '11分钟',
          label: '11分钟'
        },
        {
          value: '12分钟',
          label: '12分钟'
        },
        {
          value: '13分钟',
          label: '13分钟'
        },
        {
          value: '14分钟',
          label: '14分钟'
        },
        {
          value: '15分钟',
          label: '15分钟'
        }
      ],
      num: [
        {
          value: '1次/日',
          label: '1次/日'
        },
        {
          value: '2次/日',
          label: '2次/日'
        },
        {
          value: '1次/周',
          label: '1次/周'
        },
        {
          value: '2次/周',
          label: '2次/周'
        },
      ],
      zq: [
        {
          value: '1周',
          label: '1周'
        },
        {
          value: '2周',
          label: '2周'
        },
        {
          value: '3周',
          label: '3周'
        },
        {
          value: '4周',
          label: '4周'
        },
        {
          value: '5周',
          label: '5周'
        },
        {
          value: '6周',
          label: '6周'
        },
        {
          value: '7周',
          label: '7周'
        },
        {
          value: '8周',
          label: '8周'
        },
        {
          value: '9周',
          label: '9周'
        },
        {
          value: '10周',
          label: '10周'
        },
        {
          value: '11周',
          label: '11周'
        },
        {
          value: '12周',
          label: '12周'
        },
        {
          value: '1个月',
          label: '1个月'
        },
        {
          value: '2个月',
          label: '2个月'
        },
        {
          value: '3个月',
          label: '3个月'
        },
      ],
      fs: [
        {
          value: '训练室',
          label: '训练室'
        },
        {
          value: '家庭',
          label: '家庭'
        }
      ]
    }
  },
  created() {
    this._api.training.getPlanData()
    .then(res => {
      if (res.GetListResult && res.GetListResult.length > 0) {
        for (let i = 0; i < res.GetListResult.length; i++) {
          res.GetListResult[i].S_OP_Json = this._clJson(res.GetListResult[i].S_OP_Json)
        }
        this.arr = res.GetListResult
      }
      this._api.eyeTest.getViewFnTest()
          .then(res => {
            if (res.GetResult) {
              let datap = res.GetResult
              datap.S_OP_Json = this._clJson(datap.S_OP_Json)
              let str = datap.S_OP_Json.S_OP_VF_Diagnosis,list = []
              if (str[str.length-1] == ',') {
                let arr = str.split(',')
                arr.pop()
                str = arr.join(',')
              }
              this.title = str
              if (this.arr.length <= 0) {
                if (str) {
                  let strarr = str.split(',')
                  list = Array.from(this.inits(strarr).values())
                }
                this.arr = list
              }
            }
          })
    })

  },
  methods: {
    add() {
      this.arr.push({
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_ExpertId: '00000000-0000-0000-0000-000000000000',
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_Type: 'InspectionTraining',
        S_OP_Model: 'S_OP_InspectionTraining180726',
        S_OP_Json: {
          S_OP_IT_VFConclusion: name,
          S_OP_IT_Tool: '',
          S_OP_IT_Stage: '',
          S_OP_IT_Duration: '',
          S_OP_IT_ChooseId: this.$store.state.physicianVisits.xzConsulting,
          S_OP_IT_Frequency: '',
          S_OP_IT_Method: '',
          S_OP_IT_Eyes: '',
          S_OP_IT_Period: ''
        }
      })
    },
    dele(idx) {
      // this.arr.splice(idx, 1)
      //console.log(idx)
      let pp = [1,2,3,4,5,6,7,8]
      this.arr.splice(idx, 1)
      //console.log(pp)
    },
    inits(arrVal) {
      let item = new Map()
      item.set('调节训练', [
        '镜片阅读-镜片箱+视力卡（近）',
        '镜片排序-镜片箱+视力卡（近）',
        '调节幅度-字母表（远近交替）',
        '调节灵活度-双面镜+视力卡（近）'
      ])
      item.set('脱抑制训练', [
        '脱抑制训练-红绿阅读单位+红绿眼镜+字母表（远）',
        '脱抑制训练-单侧倾斜实体镜（远）',
        '脱抑制训练-红绿扑克+红绿眼镜（近）',
        '脱抑制训练-红绿阅读单位+红绿眼镜+视力卡（近）',
        '增强脱抑制训练-双面镜+2.00+红绿阅读单位+红绿眼镜+视力卡（近）'
      ])
      item.set('融像训练', [
        '融像感知训练-马斯登球+手持棱镜（远）',
        '集合训练-集合卡（近）',
        '集合训练-聚散球（远近交替）',
        '平滑融像训练-单侧倾斜实体镜（远）',
        '平滑融像训练-可变矢量图（近）',
        '平滑融像训练-绳圈图（近）',
        '平滑融像训练-小丑图（近）',
        '跳跃融像训练-B-0立体镜+摩根训练卡（远）',
        '跳跃融像训练-固变矢量图（近）',
        '跳跃融像训练-裂隙尺（近）',
        '自主融像训练-偏心环卡（近）',
        '自主融像训练-偏心的同心圆卡（近）',
        '自主融像训练-救生圈卡（近）'
      ])
      item.set('动眼功能训练', [
        '追随、扫视训练-视觉追随练习簿（近）',
        '整合协调训练-大象盘（远）',
        '整合协调训练-旋转钉板（近）',
        '整合协调训练-平衡木+马斯登球（远）'
      ])

      let jl = new Map()
      jl.set('调节不足', [
          ['调节训练', [0,1,2,3]],
          ['脱抑制训练', [2,3,4]],
          ['融像训练', [2]],
          ['动眼功能训练', [0,2]]
      ])
      jl.set('调节灵活度不良', [
        ['调节训练', [0,1]],
        ['脱抑制训练', [4]],
        ['融像训练', [2]],
        ['动眼功能训练', [0,2]]
      ])
      jl.set('调节不能持久', [
        ['调节训练', [0,1,2,3]],
        ['脱抑制训练', [4]],
        ['动眼功能训练', [0]]
      ])
      jl.set('调节过度', [
        ['调节训练', [0,1,2]],
        ['融像训练', [2]]
      ])
      jl.set('集合不足', [
        ['调节训练', [3]],
        ['脱抑制训练', [2,3,4]],
        ['融像训练', [0,1,2,3,4,5,6,7,8,9,10,11,12]],
        ['动眼功能训练', [0,2]]
      ])
      jl.set('集合过度', [
        ['调节训练', [3]],
        ['脱抑制训练', [2,3,4]],
        ['融像训练', [0,1,2,3,4,5,6,7,8,9,10,11,12]]
      ])
      jl.set('散开不足', [
        ['调节训练', [3]],
        ['脱抑制训练', [0,1]],
        ['融像训练', [0,1,2,3,4,5,6,7,8,9,10,11,12]],
        ['动眼功能训练', [1,3]]
      ])
      jl.set('散开过度', [
        ['调节训练', [3]],
        ['脱抑制训练', [0,1]],
        ['融像训练', [0,1,2,3,4,5,6,7,8,9,10,11,12]],
        ['动眼功能训练', [1,3]]
      ])
      jl.set('单纯性外隐斜', [
        ['调节训练', [3]],
        ['脱抑制训练', [0,1,2,3,4]],
        ['融像训练', [0,1,2,3,4,5,6,7,8,9,10,11,12]],
        ['动眼功能训练', [1,3]]
      ])
      jl.set('单纯性内隐斜', [
        ['调节训练', [3]],
        ['脱抑制训练', [0,1,2,3,4]],
        ['融像训练', [0,1,2,3,4,5,6,7,8,9,10,11,12]],
        ['动眼功能训练', [0,1,2,3]]
      ])
      jl.set('融像性聚散减低', [
        ['调节训练', [3]],
        ['融像训练', [0,1,2,3,4,5,6,7,8,9,10,11,12]],
        ['动眼功能训练', [0,1,2,3]]
      ])
      jl.set('假性集合不足', [
        ['调节训练', [0,1,2,3]],
        ['融像训练', [3]],
        ['动眼功能训练', [0]]
      ])

      let all = new Map()
      for (let i = 0; i < arrVal.length; i++) {
        if (!jl.get(arrVal[i])) {continue}
        for (let j = 0; j < jl.get(arrVal[i]).length; j++) {
          let name = jl.get(arrVal[i])[j][0]
          for (let k = 0; k < jl.get(arrVal[i])[j][1].length; k++) {
            let arr = jl.get(arrVal[i])[j][1]
            let val = item.get(name)[arr[k]]
            all.set(name+jl.get(arrVal[i])[j][1][k], {
              S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
              S_OP_ExpertId: '00000000-0000-0000-0000-000000000000',
              S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
              S_OP_Type: 'InspectionTraining',
              S_OP_Model: 'S_OP_InspectionTraining180726',
              S_OP_Json: {
                S_OP_IT_VFConclusion: name,
                S_OP_IT_Tool: val,
                type: 1,
                S_OP_IT_Stage: arrVal[i],
                S_OP_IT_Duration: '10分钟',
                S_OP_IT_ChooseId: this.$store.state.physicianVisits.xzConsulting,
                S_OP_IT_Frequency: '1次/周',
                S_OP_IT_Method: '训练室',
                S_OP_IT_Eyes: '双眼',
                S_OP_IT_Period: '一周'
              }
            })
          }
        }
      }
      return all
    },
    saves() {
      let arrs = [], datas = JSON.parse(JSON.stringify(this.arr))

      if (datas.length) {
        for (let i = 0; i < datas.length; i++) {
          datas[i].S_OP_Json = pf.conversionJson(datas[i].S_OP_Json)
          arrs.push(datas[i])
        }
        axios.post(pf.getUrl('S_all_Normal_SaveList'), {
          "token": {
            "sToken": "45B94332B4508AAE81295EB24C982654",
            "TimeSpan": "1623829629"
          },
          lo: datas
        })
            .then(res => {
              if (res.UpdateResult) {
                this.$alert('保存成功！', '提示')
              }else  {
                this.$alert('保存失败！', '提示')
              }
            })
      }else {
        axios.post(pf.getUrl('S_OP_ALL_DeleteByType'), {
          "token": {
            "sToken": "45B94332B4508AAE81295EB24C982654",
            "TimeSpan": "1623829629"
          },
          ChooseId: this.$store.state.physicianVisits.xzConsulting,
          S_OP_Model: "S_OP_InspectionTraining180726",
          S_OP_Type: "InspectionTraining"
        })
            .then(res => {
              // console.log(res)
              if (res.DeleteByTypeResult) {
                this.$alert('保存成功!', '提示')
              }else {
                this.$alert('保存失败!', '提示')
              }
            })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .trainingPlan {
    width: 100vw;
    height: 87vh;
    overflow: hidden;
  }
  .topl {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    padding: 3vh 5vw;
    .topl-box {
      display: flex;
      align-items: center;
      justify-content: start;
    }
  }

  .bakc {
    li {background: #efefef;}
  }
  .content {
    width: 90vw;
    padding: 0 5vw;
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: .1rem 0;
      margin-right: 1px;
      width: 10%;
    }
    input {
      display: block;
      outline: none;
      border: none;
      text-align: center;
      border-bottom: 1px solid #5a5959;
      width: 95%;
      padding: .1rem 0;
    }
    .one {
      width: 11%;
      i {
        font-weight: bold;
        font-size: 18px;
      }
      p {padding: 0 .05rem;}
    }
    .tow {
      width: 28%;
    }
  }
  .content-box {
    height: 63vh;
    overflow: hidden;
    overflow-y: auto;
  }
</style>
